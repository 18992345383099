<template>
 <div
  class="fixed impersonate_z_index inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div
   class="flex items-center justify-center impersonate_card_height_position pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"></div>
   <span
    class="hidden sm:inline-block sm:align-middle sm:h-screen"
    aria-hidden="true"
    >&#8203;</span
   >

   <div
    class="rounded-lg inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
    <div>
     <form @submit.prevent="downloadReport()" method="POST">
      <div class="space-y-12">
       <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-xl font-semibold leading-7 text-gray-900">
         {{ $t("Télécharger tous les Log d'appels sur une période sélectionnée") }}
        </h2>
        <p class="text-sm leading-6 text-gray-600">
         {{
          $t(
           "Sélectionnez une période pour demander les journaux d'appels. Le rapport CSV peut prendre du temps à être généré."
          )
         }}
        </p>

        <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
         <div class="sm:col-span-6 mx-4">
          <label
           for="start_date"
           class="block text-sm font-medium leading-6 text-gray-900">
           {{ $t("Date de début") }}
          </label>
          <div class="mt-1">
           <input
            required
            v-model="startDate"
            type="date"
            name="start_date"
            id="start_date"
            pattern="\\d{4}-\\d{2}-\\d{2}"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
          </div>
         </div>

         <div class="sm:col-span-6 mx-4">
          <label
           for="end_date"
           class="block text-sm font-medium leading-6 text-gray-900">
           {{ $t("Date de fin") }}
          </label>
          <div class="mt-1">
           <input
            required
            v-model="endDate"
            type="date"
            name="end_date"
            id="end_date"
            pattern="\\d{4}-\\d{2}-\\d{2}"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
          </div>
         </div>
        </div>
       </div>
      </div>

      <!-- Progress Bar -->
      <div v-if="isDownloading" class="mt-4">
       <div class="relative pt-1">
        <div class="flex mb-2 items-center justify-between">
         <div>
          <span class="text-xs font-semibold inline-block text-green-600"
           >{{ progress }}%</span
          >
         </div>
        </div>
        <div class="overflow-hidden h-2 text-xs flex rounded bg-gray-300">
         <div
          :style="{ width: progress + '%' }"
          class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
        </div>
       </div>
      </div>

      <div class="mt-6 flex items-center justify-end gap-x-2">
       <button
        type="button"
        @click="$emit('closeForm')"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none">
        {{ $t("cancel") }}
       </button>
       <button
        type="submit"
        class="rounded-md encom_primary encom_primary_hover px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        {{ $t("Télécharger") }}
       </button>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
 name: "impersonate",
 props: ["messageToShow", "subMessageToShow", "reload", "error"],
 data() {
  return {
   startDate: "",
   endDate: "",
   isDownloading: false,
   progress: 0,
  };
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
 methods: {
  async downloadReport() {
   this.$emit("setLoading", true);
   this.isDownloading = true;
   this.progress = 0;

   const params = {
    start_date: this.startDate,
    end_date: this.endDate,
   };
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/host-report-file`;

   try {
    const res = await axios.post(url, params, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
     responseType: "blob",
     onDownloadProgress: progressEvent => {
      if (progressEvent.lengthComputable) {
       this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
       );
      }
     },
    });

    const blob = new Blob([res.data], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const reportDate = `${params.start_date}_to_${params.end_date}`;
    link.download = `Full_Report_${reportDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
   } catch (error) {
    this.errorHandling(error);
   }

   this.isDownloading = false;
   this.progress = 0;
   this.$emit("setLoading", false);
  },
 },
};
</script>

<style>
.impersonate_z_index {
 z-index: 9999;
}
.impersonate_card_height_position {
 min-height: 100vh;
}
</style>
