<template>
 <div class="flex bg-gray-100 min_height_app">
  <Sidebar
   @closeTicketDetail="closeTicketDetail = !closeTicketDetail"
   @deactiveHover="sideNavHover = false"
   @openSideNavHover="openSideNavHover($event)"
   :navigation="navigationLong"
   :secondaryNavigation="secondaryNavigation"
   :specialNavigation="updatedSpecialNavigation"
   :showSpecialNavigation="showSpecialNavigation"
   :statisticsAvailableForProduction="statisticsAvailableForProduction"
   v-if="
    this.$route.path !== '/login' &&
    this.$route.name !== 'TrainingQuizDetail' &&
    this.$route.name !== 'ResetPassword' &&
    this.$route.name !== 'API404' &&
    this.$route.name !== 'StatisticsNotAvailable' &&
    this.$route.name !== '404'
   "
   @openNewSidebar="openSide = !openSide"
   @open-feedback="openFeedback = $event"
   @closeSubmenu="openSubmenu = false"
   :openSubmenu="openSubmenu"
   :userPermissions="
    userPermissions && userPermissions.permissions
     ? userPermissions.permissions
     : []
   " />

  <div class="flex flex-col flex-1 overflow-hidden">
   <AccouncementBanner />
   <MainHeader
    v-if="
     this.$route.path !== '/login' &&
     this.$route.name !== 'TrainingQuizDetail' &&
     this.$route.name !== 'ResetPassword' &&
     this.$route.name !== 'API404' &&
     this.$route.name !== 'StatisticsNotAvailable' &&
     this.$route.name !== '404'
    "
    @openSubmenu="openSubmenu = true"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    @close-feedback="openFeedback = $event"
    v-model:search="search"
    :openFeedback="openFeedback"
    :getPbxHosts="getPbxHosts"
    @setLoading="isLoading = $event" />
   <div class="flex items-stretch flex-1 overflow-hidden">
    <aside
     v-show="
      showSideNav &&
      this.$route.name !== 'Login' &&
      this.$route.name !== 'Dashboard' &&
      this.$route.name !== 'DashboardTrend' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      this.$route.name !== 'ResetPassword' &&
      this.$route.name !== 'StatisticsNotAvailable' &&
      this.$route.name !== '404' &&
      this.$route.name !== 'Error' &&
      this.$route.name !== 'API404' &&
      this.$route.name !== 'GetStarted' &&
      this.$route.name !== 'GetStartedExpertStats' &&
      this.$route.name !== 'Unauthorized' &&
      this.$route.name !== 'EnableService' &&
      this.$route.name !== 'SMS' &&
      !this.$route.path.includes('/groups') &&
      !this.$route.path.includes('configuration') &&
      hasSideNav &&
      sideNavHover &&
      !hideSideNav
     "
     class="hidden overflow-y-auto border-t border-r border-gray-100 border-gray-300 side_nav_width navbar lg:block">
     <SideNav
      @openReportForm="openReportForm = true"
      @closeSideNavHover="sideNavHover = false"
      @deactiveHover="sideNavHover = false"
      :isHover="true"
      @hasNavigation="hasSideNav = $event"
      @showSideNav="showSideNav = false"
      :navigation="
       sideHoverNav === 'sidebar.statistics'
        ? statisticsNavigation
        : sideHoverNav === `Expert Stats`
        ? expertStatisticsNavigation
        : sideHoverNav === 'sidebar.specialNavigation.smartRoutings'
        ? expertNavigation
        : sideHoverNav === 'SMS'
        ? smsNavigation
        : sideHoverNav === 'sidebar.tickets.tickets'
        ? ticketNavigation
        : sideHoverNav === 'Arbres plantés'
        ? certificatesNavigation
        : sideHoverNav === 'Training'
        ? trainingNavigation
        : ''
      " />
    </aside>
    <aside
     v-show="
      this.$route.name !== 'Login' &&
      this.$route.name !== 'Dashboard' &&
      this.$route.name !== 'DashboardTrend' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      this.$route.name !== 'ResetPassword' &&
      this.$route.name !== '404' &&
      this.$route.name !== 'StatisticsNotAvailable' &&
      this.$route.name !== 'StatisticsNotAvailable' &&
      this.$route.name !== 'Error' &&
      this.$route.name !== 'API404' &&
      this.$route.name !== 'GetStarted' &&
      this.$route.name !== 'GetStartedExpertStats' &&
      this.$route.name !== 'Unauthorized' &&
      this.$route.name !== 'CustomerContacts' &&
      this.$route.name !== 'CustomerContactDetail' &&
      this.$route.name !== 'EnableService' &&
      this.$route.name !== 'SMS' &&
      !this.$route.path.includes('invoices') &&
      !this.$route.path.includes('documents') &&
      !this.$route.path.includes('profile') &&
      !this.$route.path.includes('changelog') &&
      !this.$route.path.includes('customer-files') &&
      !this.$route.path.includes('web-radios') &&
      !this.$route.path.includes('/groups') &&
      !this.$route.path.includes('configuration') &&
      !sideNavHover &&
      showSideNav &&
      !hideSideNav
     "
     class="hidden overflow-y-auto border-t border-r border-gray-100 border-gray-300 side_nav_width navbar lg:block">
     <SideNav
      @openReportForm="openReportForm = true"
      @hasNavigation="hasSideNav = $event"
      @deactiveHover="sideNavHover = false"
      @showSideNav="showSideNav = false"
      v-if="this.$route.name"
      :navigation="
       this.$route.name.includes('PBX')
        ? statisticsNavigation
        : this.$route.name.includes('ExpertStatistics') &&
          !this.$route.path.includes('/groups') &&
          !this.$route.path.includes('configuration')
        ? expertStatisticsNavigation
        : this.$route.name.includes('Expert3cx')
        ? expertNavigation
        : this.$route.path.includes('sms')
        ? smsNavigation
        : this.$route.path.includes('ticket')
        ? ticketNavigation
        : this.$route.path.includes('training')
        ? trainingNavigation
        : ''
      " />
    </aside>
    <aside
     v-show="
      !showSideNav &&
      this.$route.name !== 'Dashboard' &&
      this.$route.name !== 'DashboardTrend' &&
      this.$route.name !== 'Error' &&
      this.$route.name !== 'Login' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      !this.$route.path.includes('invoices') &&
      !this.$route.path.includes('documents') &&
      !this.$route.path.includes('web-radios') &&
      !this.$route.path.includes('/groups') &&
      !this.$route.path.includes('configuration') &&
      !hideSideNav
     "
     class="hidden side_nav_off_width navbar border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300">
     <SideNavOff @showSideNav="showSideNav = true" />
    </aside>
    <main class="flex-1 overflow-y-hidden">
     <!-- Primary column -->
     <section
      aria-labelledby="primary-heading"
      :class="[
       this.$route.name !== 'Login' &&
       this.$route.name !== 'Dashboard' &&
       this.$route.name !== 'DashboardTrend' &&
       this.$route.name !== 'TrainingQuizDetail' &&
       this.$route.name !== 'ResetPassword' &&
       this.$route.name !== 'StatisticsNotAvailable' &&
       this.$route.name !== 'StatisticsNotAvailable' &&
       this.$route.name !== '404' &&
       this.$route.name !== 'API404' &&
       this.$route.name !== 'Error' &&
       this.$route.name !== 'GetStarted' &&
       this.$route.name !== 'GetStartedExpertStats' &&
       this.$route.name !== 'Unauthorized' &&
       this.$route.name !== 'EnableService' &&
       this.$route.name !== 'SMS' &&
       this.$route.name !== 'CustomerContacts' &&
       this.$route.name !== 'CustomerContactDetail' &&
       !this.$route.path.includes('/groups') &&
       !this.$route.path.includes('configuration') &&
       !this.$route.path.includes('invoices') &&
       !this.$route.path.includes('documents') &&
       !this.$route.path.includes('profile') &&
       !this.$route.path.includes('changelog') &&
       !this.$route.path.includes('customer-files') &&
       !this.$route.path.includes('web-radios') &&
       showSideNav
        ? 'main__sidenav--on'
        : this.$route.name !== 'Login' &&
          this.$route.name !== 'Dashboard' &&
          this.$route.name !== 'DashboardTrend' &&
          this.$route.name !== 'TrainingQuizDetail' &&
          this.$route.name !== 'ResetPassword' &&
          this.$route.name !== 'StatisticsNotAvailable' &&
          this.$route.name !== '404' &&
          this.$route.name !== 'API404' &&
          this.$route.name !== 'Error' &&
          this.$route.name !== 'GetStarted' &&
          this.$route.name !== 'GetStartedExpertStats' &&
          this.$route.name !== 'Unauthorized' &&
          this.$route.name !== 'EnableService' &&
          this.$route.name !== 'SMS' &&
          this.$route.name !== 'CustomerContacts' &&
          this.$route.name !== 'CustomerContactDetail' &&
          !this.$route.path.includes('/groups') &&
          !this.$route.path.includes('configuration') &&
          !this.$route.path.includes('invoices') &&
          !this.$route.path.includes('documents') &&
          !this.$route.path.includes('profile') &&
          !this.$route.path.includes('changelog') &&
          !this.$route.path.includes('customer-files') &&
          !this.$route.path.includes('web-radios') &&
          !showSideNav
        ? 'main__sidenav--off'
        : this.$route.name !== 'Login' &&
          this.$route.name !== '404' &&
          this.$route.name !== 'StatisticsNotAvailable' &&
          this.$route.name !== 'GetStarted' &&
          this.$route.name !== 'GetStartedExpertStats' &&
          this.$route.name !== 'Unauthorized' &&
          this.$route.name !== 'EnableService'
        ? 'main__no_sidenav'
        : '',
       'min-w-0 flex-1 h-full flex-col lg:order-last',
      ]">
      <SuccessBanner
       :key="key"
       :msg="successMsg"
       :active="activeBanner"
       @closeBanner="activeBanner = false"
       :isSuccess="isSuccess" />

      <router-view
       :isGuestUser="isGuestUser"
       :key="$route.fullPath && hostName"
       :closeTicketDetail="closeTicketDetail"
       :search="search"
       @tickesCount="(...args) => getTicketCount(...args)"
       @setLoading="isLoading = $event"
       @activeBanner="(activeBanner = true), (key = !key)"
       @successMsg="successMsg = $event"
       @success="isSuccess = $event"
       v-model:search="search"
       :getPbxHosts="getPbxHosts"
       :showSideNav="showSideNav" />
     </section>
    </main>
    <div v-if="openReportForm">
     <RequestCallLogs
      @close-form="openReportForm = false"
      @setLoading="(isLoading = $event), (openReportForm = false)"
      @activeBanner="$emit('activeBanner')"
      @success="$emit('success', $event)"
      @successMsg="$emit('successMsg', $event)" />
    </div>
    <!-- Secondary column (hidden on smaller screens) -->
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :is-full-page="true"
  :lock-scroll="true"
  opacity="0.9"
  color="#1e7889" />
 <LoadingMessage
  v-show="isLoading"
  title="Récupération des données en cours."
  text="Temps d'attente maximum : 1 minute." />
</template>

<script>
import SuccessBanner from "./components/SuccessBanner.vue";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import AccouncementBanner from "./components/AnnouncementBanner.vue";
import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/Sidebar.vue";
import Banner from "./components/Banner.vue";
import SideNav from "./components/SideNav.vue";
import SideNavOff from "./components/SideNavOff.vue";
import { mapGetters, mapState } from "vuex";
import LoadingMessage from "./components/LoadingMessage.vue";
import RequestCallLogs from "./components/PBX/RequestCallLogs.vue";

const adminToken = localStorage.getItem("adminToken");
const showBanner = localStorage.getItem("showBanner");

const sandbox = localStorage.getItem("sandbox");
const isLocal = localStorage.getItem("isLocal");

const adminUsers = [
 "cyrille.mariot-thierry@laligneverte.fr",
 "mbrigini@elcia.com",
 "edmon.youssif@stannah.fr",
 "jean-raymond.lebrun@widex.fr",
 "jmg.paris.fr@gmail.com",
 "tgirard@asia.fr",
 "clementcoupin@reedmackay.com",
 "bernard@granitsmaffre.com",
 "lauh@audika.com",
 "f.linval@beachcombertours.fr",
 "christophe.tonellato@winpharma.com",
 "stanislasroblemoine@gmail.com",
 "stephane.barone@habitat77.fr",
 "chleclerc@chlconseil.fr",
 "schany.taix@apm.fr",
 "cchemineau@autovision.fr",
 "maxime.veron@squarehabitat-ndf.fr",
 "pbruneau@gritchen.fr",
 "garcia.guy@declicnet.fr",
 "m.moriaud@anact.fr",
 "vincent.lecanu@groupe-arcade.com",
 "victor.mary@mary.fr",
 "issam.mohsen@habitat.fr",
 "Vincent.PICARD@grandbourg.fr",
 "jeremy.martins@groupe-altair.fr",
 "comptabilite@loof.asso.fr",
 "compta@inaxel.com",
 "Pascal.mazza@axio-informatique.fr",
 "ckersuzan@groupcls.com",
 "gbernat@alcopa-auction.fr",
 "roland.stern@lec.info",
 "stephane.navarro@kuoni.fr",
 "cyrille.georgel@gmail.com",
 "cyprien.barthez@ca-immobilier.fr",
 "laurent.vuaille@ocea-sb.com",
 "kdevos@impf.fr",
 "xavier@tertronic.com",
 "embarek@staf-transports.com",
 "sebastien.saussette@plassonfrance.fr",
 "vincentmerian@ati35.asso.fr",
 "laura@epoca.health",
 "christine.leal@districlos.com",
];

const navigationShort = [
 {
  name: "dashboard",
  route: "Dashboard",
  show: true,
  icon: "home",
  href: "/dashboard",
  current: false,
  module: "ticketing",
  hasSideNav: false,
  permissionName: "standard-statistics",
  serviceName: "standard-stats",
  permissions: ["standard-statistics.*", "standard-statistics.view"],
 },
 {
  name: "sidebar.tickets.tickets",
  route: "Tickets",
  show: true,
  href: "/tickets",
  current: false,
  icon: "user-headset",
  children: [
   { name: "sidebar.tickets.open", href: "/tickets" },
   { name: "sidebar.tickets.closed", href: "/closed-tickets/1" },
  ],
  hasSideNav: true,
  permissionName: "ticketing",
  module: "ticketing",
  showModule: true,
 },
 {
  name: "sidebar.invoices",
  route: "Invoices",
  show: true,
  href: "/invoices",
  icon: "envelope-open-dollar",
  current: false,
  hasSideNav: false,
  permissionName: "invoicing",
 },
 {
  name: "users",
  route: "CustomerContacts",
  show: true,
  href: "/contacts",
  icon: "users",
  current: false,
  hasSideNav: false,
  permissionName: "contacts",
 },
];
const navigationLong = [
 {
  name: "Tableau de bord 3CX",
  route: "Dashboard",
  show: false,
  icon: "gauge-high",
  href: "/dashboard",
  module: "dashboard",
  current: false,
  hasSideNav: false,
  permissionName: "expert-statistics",
  serviceName: "expert-stats",
  permissions: ["expert-statistics.*", "expert-statistics.view"],
  beta: false,
 },
 {
  name: "sidebar.tickets.tickets",
  route: "Tickets",
  show: false,
  href: "/tickets",
  current: false,
  icon: "user-headset",
  children: [
   { name: "sidebar.tickets.open", href: "/tickets" },
   { name: "sidebar.tickets.closed", href: "/closed-tickets" },
  ],
  hasSideNav: true,
  permissionName: "ticketing",
  permissions: ["ticketing.*", "ticketing.view", "ticketing.view-own"],
  module: "ticketing",
  showModule: true,
 },
 {
  name: "sidebar.invoices",
  route: "Invoices",
  show: false,
  icon: "envelope-open-dollar",
  href: "/invoices",
  current: false,
  hasSideNav: false,
  permissions: ["invoicing.*", "invoicing.view"],
  permissionName: "invoicing",
 },
 {
  name: "users",
  route: "CustomerContacts",
  show: true,
  href: "/contacts",
  icon: "users",
  current: false,
  hasSideNav: false,
  permissionName: "contacts",
  permissions: ["contacts.*", "contacts.view"],
 },
 /*  {
  name: "Documents",
  route: "Documents",
  show: true,
  href: "/documents",
  icon: "folder-open",
  current: false,
  hasSideNav: false,
  permissionName: "customers",
 },
 {
  name: "sidebar.collects",
  route: "Folder",
  show: false,
  href: "/customer-files",
  icon: "file-signature",
  current: false,
  hasSideNav: false,
  permissionName: "information-collections",
 }, */
];

const specialNavigation = [
 {
  name: "Expert Stats",
  route: "ExpertStatistics",
  show: false,
  href: "/expert-statistics/home/intro",
  icon: "chart-pie",
  current: false,
  children: [
   {
    name: "Mes Numéros",
    href: "",
    children: [
     {
      name: "Rapport détaillé",
      href: "/expert-statistics/did-report",
     },
    ],
   },
   {
    name: "Mes Files d'attente",
    href: "",
    children: [
     {
      name: "Tableau de Bord",
      href: "/dashboard/queue",
     },
     {
      name: "Rapport détaillé",
      href: "/expert-statistics/full-report/queue",
     },
     {
      name: "KPI",
      href: "/expert-statistics-answered-unanswered/queue",
     },
     //  {
     //   name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
     //   href: "/expert-statistics/answered/daily/queue",
     //  },
     {
      name: "expertStatisticsNavigation.origins",
      href: "/expert-statistics/queue",
     },
     //  {
     //   name: "expertStatisticsNavigation.originsTop10",
     //   href: "/expert-statistics-origin-detail/queue",
     //  },
    ],
   },
   {
    name: "Mes utilisateurs",
    href: "",
    children: [
     {
      name: "Tableau de Bord",
      href: "/dashboard/extension",
     },
     {
      name: "KPI",
      href: "/expert-statistics-answered-unanswered/extension",
     },
     //  {
     //   name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
     //   href: "/expert-statistics/answered/daily/extension",
     //  },
     {
      name: "expertStatisticsNavigation.origins",
      href: "/expert-statistics/extension",
     },
     //  {
     //   name: "expertStatisticsNavigation.originsTop10",
     //   href: "/expert-statistics-origin-detail/extension",
     //  },
    ],
   },
   //  {
   //   name: "Suivi des appels",
   //   href: "",

   //   children: [
   //    {
   //     name: "expertStatisticsNavigation.thirdPartyNumbers",
   //     href: "/expert-statistics/third-party-numbers",
   //     beta: true,
   //    },
   //   ],
   //  },
   //  {
   //   name: "Configuration",
   //   href: "/expert-statistics/configuration/personal",
   //   namePath: "ExpertStatisticsConfiguration",
   //  },
  ],
  hasSideNav: true,
  permissionName: "expert-statistics",
  serviceName: "expert-stats",
  permissions: ["expert-statistics.*", "expert-statistics.view"],
 },
 {
  name: "sidebar.specialNavigation.smartRoutings",
  route: "Expert3cx",
  href: "/contacts-routes",
  current: false,
  show: false,
  icon: "code-pull-request",
  children: [
   { name: "sidebar.specialNavigation.contact", href: "/contacts-routes" },
   { name: "Configuration CTI", href: "/cti" },
   { name: "sidebar.specialNavigation.georouting", href: "/georouting" },
   {
    name: "sidebar.specialNavigation.callqueuegroup",
    href: "/callqueues-groups",
   },
   { name: "inbound rules", href: "/callqueues" },
  ],
  hasSideNav: true,
  permissionName: "smart-routings",
  serviceName: "smart-routings",
  permissions: ["smart-routings.*", "smart-routings.view"],
 },

 //  {
 //   name: "SMS",
 //   route: "SMS",
 //   show: false,
 //   href: "/sms",
 //   icon: "sms",
 //   current: false,
 //   children: [
 //    { name: "Recipients", href: "/sms-recipients/list" },
 //    { name: "Campaign", href: "/sms-campaign/campaign" },
 //    { name: "configuration", href: "/sms/configuration" },
 //   ],
 //   hasSideNav: true,
 //   permissionName: "sms",
 //   serviceName: "sms",
 //   permissions: ["sms.*", "sms.view"],
 //  },
 {
  name: "webRadios",
  route: "WebRadios",
  href: "/web-radios",
  icon: "tower-broadcast",
  current: false,
  children: [],
  hasSideNav: false,
  permissionName: "webradios",
  permissions: ["webradios.*", "webradios.view"],
 },
 {
  name: "Training",
  show: false,
  href: "/training/my-courses",
  icon: "graduation-cap",
  current: false,
  children: [
   { name: "courses", href: "/training/courses" },
   { name: "myCourses", href: "/training/my-courses" },
   //  { name: "Quizzes", href: "/training/quizzes" },
   // { name: "Single Message", href: "/sms-single-message" },
  ],
  hasSideNav: true,
  permissionName: "training",
  module: "training",
  permissions: ["training.*", "training.view"],
 },
];

const trainingNavigation = [
 { name: "courses", href: "/training/courses" },
 { name: "myCourses", href: "/training/my-courses" },
 //  { name: "Quizzes", href: "/training/quizzes" },
];

const statisticsNavigation = [
 { name: "sidebar.subMenu.callQueue", href: "/pbx-callqueue" },
 { name: "Extension", href: "/pbx-extension" },
 { name: "Top 20", href: "/top-20-statistics" },
];

const expertStatisticsNavigationOld = [
 {
  name: "sidebar.subMenu.callQueue",
  href: "",
  children: [
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/queue",
   },
   {
    name: "expertStatisticsNavigation.originsTop10",
    href: "/expert-statistics-origin-detail/queue",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCalls",
    href: "/expert-statistics-answered-unanswered/queue",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
    href: "/expert-statistics/answered/daily/queue",
   },
   //  {
   //   name: "expertStatisticsNavigation.answeredUnansweredCallsWeekDay",
   //   href: "/expert-statistics-answered-unanswered/week-day/queue",
   //  },
   //  {
   //     name: "expertStatisticsNavigation.answeredUnansweredCallsWeekYear",
   //   href: "/expert-statistics-answered-unanswered/week-year/queue",
   //  },
   {
    name: "details",
    href: "/expert-statistics/full-report/queue",
   },
   //  {
   //   name: "waitingTime",
   //   href: "/expert-statistics-call-durations/waiting-time/queue",
   //  },
   //  {
   //   name: "durations",
   //   href: "/expert-statistics-call-durations/queue",
   //  },
   //  {
   //   name: "queueComparison",
   //   href: "/expert-statistics/compare-queues",
   //  },
   {
    name: "didReport",
    href: "/expert-statistics/did-report",
   },
   // {
   //  name: "didCallers",
   //  href: "/expert-statistics/did",
   // },
   //  {
   //   name: "expertStatisticsNavigation.callerNumbers",
   //   href: "/expert-statistics/caller-numbers",
   //   beta: true,
   //  },
   //  {
   //   name: "expertStatisticsNavigation.thirdPartyNumbers",
   //   href: "/expert-statistics/third-party-numbers",
   //   beta: true,
   //  },
   {
    name: "expertStatisticsNavigation.groups",
    href: "/expert-statistics/groups/queue",
   },
   // {
   //   name: "expertStatisticsNavigation.hourlyStatistics",
   //   href: "",
   // },
   {
    name: "sidebar.specialNavigation.reportList",
    href: "/expert-statistics/reports/queue/1",
   },
  ],
 },
 {
  name: "Extension",
  href: "",
  children: [
   // {
   //   name: "expertStatisticsNavigation.advancedStatistics",
   //   href: "",
   // },
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/extension",
   },
   {
    name: "expertStatisticsNavigation.originsTop10",
    href: "/expert-statistics-origin-detail/extension",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCalls",
    href: "/expert-statistics-answered-unanswered/extension",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
    href: "/expert-statistics/answered/daily/extension",
   },
   // {
   //  name: "waitingTime",
   //  href: "/expert-statistics-call-durations/extension",
   // },
   //  {
   //   name: "durations",
   //   href: "/expert-statistics-call-durations/extension",
   //  },
   {
    name: "expertStatisticsNavigation.groups",
    href: "/expert-statistics/groups/extension",
   },
   // {
   //   name: "expertStatisticsNavigation.hourlyStatistics",
   //   href: "",
   // },
   {
    name: "sidebar.specialNavigation.reportList",
    href: "/expert-statistics/reports/extension/1",
   },
  ],
 },
 {
  name: "outgoing",
  href: "/expert-statistics-outbound/extension",
  beta: true,
  // children: [
  //  {
  //   name: "expertStatisticsNavigation.overview",
  //   href: "/expert-statistics-outbound/extension",
  //  },
  //  {
  //   name: "expertStatisticsNavigation.answeredUnansweredCalls",
  //   href: "/expert-statistics-outbound/answered/extension",
  //  },
  //  {
  //   name: "expertStatisticsNavigation.durations",
  //   href: "/expert-statistics-outbound/duration/extension",
  //  },
  //  {
  //   name: "expertStatisticsNavigation.pbx",
  //   href: "/expert-statistics-outbound/pbx",
  //  },
  //  {
  //   name: "expertStatisticsNavigation.trunk",
  //   href: "/expert-statistics-outbound/trunk",
  //  },
  // ],
 },
 {
  name: "expertStatisticsNavigation.thirdPartyNumbers",
  href: "/expert-statistics/third-party-numbers",
  beta: true,
  // children: [],
 },
];

const expertStatisticsNavigation = [
 {
  name: "Mes Numéros",
  href: "",
  children: [
   {
    name: "Rapport détaillé",
    href: "/expert-statistics/did-report",
   },
  ],
 },
 {
  name: "Mes Files d'attente",
  href: "",
  children: [
   {
    name: "Tableau de Bord",
    href: "/dashboard/queue",
   },
   {
    name: "Rapport détaillé",
    href: "/expert-statistics/full-report/queue",
   },
   {
    name: "KPI",
    href: "/expert-statistics-answered-unanswered/queue",
   },
   //  {
   //   name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
   //   href: "/expert-statistics/answered/daily/queue",
   //  },
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/queue",
   },

   //  {
   //   name: "Groupes",
   //   href: "/expert-statistics/groups/queue",
   //  },
   //  {
   //   name: "expertStatisticsNavigation.originsTop10",
   //   href: "/expert-statistics-origin-detail/queue",
   //  },
  ],
 },
 {
  name: "Mes utilisateurs",
  href: "",
  children: [
   {
    name: "Tableau de Bord",
    href: "/dashboard/extension",
   },
   {
    name: "Rapport détaillé",
    href: "/expert-statistics/report/extension",
   },
   {
    name: "KPI",
    href: "/expert-statistics-answered-unanswered/extension",
   },
   //  {
   //   name: "expertStatisticsNavigation.answeredUnansweredCallsDaily",
   //   href: "/expert-statistics/answered/daily/extension",
   //  },
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/extension",
   },
   //  {
   //   name: "outgoing",
   //   href: "/expert-statistics-outbound/extension",
   //   beta: true,
   //  },
   //  {
   //   name: "Groupes",
   //   href: "/expert-statistics/groups/extension",
   //  },
   //  {
   //   name: "expertStatisticsNavigation.originsTop10",
   //   href: "/expert-statistics-origin-detail/extension",
   //  },
  ],
 },
 //{
 // name: "Investigation",
 // href: "/expert-statistics/third-party-numbers",
 // beta: true,

 // children: [
 //  {
 //   name: "expertStatisticsNavigation.thirdPartyNumbers",
 //   href: "/expert-statistics/third-party-numbers",
 //   beta: true,
 //  },
 // ],
 // },
];

const expertNavigation = [
 { name: "sidebar.specialNavigation.contact", href: "/contacts-routes" },
 { name: "Configuration CTI", href: "/cti" },
 { name: "sidebar.specialNavigation.georouting", href: "/georouting" },
 {
  name: "inbound rules groups",
  href: "/callqueues-groups",
 },
 { name: "inbound rules", href: "/callqueues" },
];

const smsNavigation = [
 // { name: "SMS List", href: "/sms" },
 { name: "Messages", href: "/sms-campaign/campaign" },
 { name: "Recipients", href: "/sms-recipients/list" },
 { name: "configuration", href: "/sms/configuration" },
 // { name: "Single Message", href: "/sms-single-message" },
];

const ticketNavigation = [
 { name: "sidebar.tickets.open", href: "/tickets", number: 0 },

 {
  name: "sidebar.tickets.closed",
  href: "/closed-tickets",
  number: 0,
 },
];

const surveyNavigation = [
 { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
 { name: "sidebar.survey.all", href: "/all-surveys/1" },
];

const certificatesNavigation = [];

const configurationNavigation = [
 {
  name: "sidebar.tickets.tickets",
  href: "/support/tickets-categories",
  children: [
   { name: "sidebar.tickets.templates", href: "/support/tickets-templates" },
   {
    name: "sidebar.support.ticketCategories",
    href: "/support/tickets-categories",
   },
   {
    name: "sidebar.support.ticketStatuses",
    href: "/support/tickets-statuses",
   },
   { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
  ],
 },
 {
  name: "sidebar.hosts.hosting",
  href: "/configuration/hosts",
  children: [
   {
    name: "sidebar.hosts.operatingSystems",
    href: "/configuration/hosting/hostOs",
   },
   {
    name: "sidebar.hosts.software",
    href: "/configuration/hosting/hostSoftwares",
   },
   { name: "sidebar.hosts.roles", href: "/configuration/hosting/hostRoles" },
   { name: "sidebar.hosts.types", href: "/configuration/hosting/hostTypes" },
   {
    name: "sidebar.hosts.shells",
    href: "/configuration/hosting/hostShells",
   },
   {
    name: "sidebar.hosts.functions",
    href: "/configuration/hosting/hostFunctions",
   },
   {
    name: "sidebar.hosts.supliers",
    href: "/configuration/hosting/hostSuppliers",
   },
   {
    name: "sidebar.hosts.datacenter",
    href: "/configuration/hosting/datacenters",
   },
   {
    name: "sidebar.hosts.hostServices",
    href: "/configuration/hosting/hostServices",
   },
  ],
 },
];

const secondaryNavigation = [];

const showSpecialNavigation = true;

const statisticsAvailableForProduction =
 process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE;
const account = localStorage.getItem("account");

export default {
 title() {
  return `${localStorage.getItem("title")}`;
 },
 components: {
  AccouncementBanner,
  MainHeader,
  Sidebar,
  Banner,
  SideNav,
  SideNavOff,
  Loading,
  SuccessBanner,
  LoadingMessage,
  RequestCallLogs,
 },
 data() {
  return {
   adminUsers,
   statisticsNavigation,
   expertStatisticsNavigation,
   expertNavigation,
   smsNavigation,
   navigationShort,
   navigationLong,
   secondaryNavigation,
   specialNavigation,
   showSpecialNavigation,
   configurationNavigation,
   surveyNavigation,
   certificatesNavigation,
   ticketNavigation,
   statisticsAvailableForProduction,
   sandbox,
   isLocal,
   cssPath: "",
   openFeedback: false,
   openReportForm: false,
   openSide: false,
   openSubmenu: false,
   showSideNav: true,
   hasSideNav: false,
   sideNavHover: false,
   sideHoverNav: "",
   hideNoNav: false,
   adminToken,
   showBanner,
   closeBannerKey: false,
   closeTicketDetail: false,
   isGuestUser: false,
   hostsNames: [],
   isLoading: false,
   search: "",
   hideSideNav: false,
   trainingNavigation,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   account,
   hasDocuments: false,
   apiVersion: "1.3.0",
  };
 },
 methods: {
  versionCheck() {
   let flag = false;
   if (this.$route.query && this.$route.query.first_login) {
    flag = true;
   }
   if (!flag) {
    if (!this.api_v || this.api_v !== this.apiVersion) {
     localStorage.setItem("api_v", this.apiVersion);
     this.$store.dispatch("api_v", this.apiVersion);
     localStorage.removeItem("token");
     localStorage.removeItem("adminToken");
     localStorage.removeItem("avatar");
     localStorage.removeItem("account");
     localStorage.removeItem("customerAccounts");
     localStorage.removeItem("activeAccount");
     localStorage.removeItem("accountName");
     localStorage.removeItem("hostName");
     localStorage.removeItem("sandbox");
     localStorage.removeItem("hostTimeZone");
     localStorage.removeItem("hostContext");
     localStorage.removeItem("showBanner");
     localStorage.removeItem("queryElement");
     localStorage.removeItem("isLocal");
     localStorage.removeItem("user");
     localStorage.removeItem("pbxMap");
     localStorage.removeItem("hostName");
     localStorage.removeItem("apiUrl");
     localStorage.removeItem("apiToken");
     localStorage.removeItem("services");
     localStorage.removeItem("userPermissions");
     localStorage.removeItem("modules");
     this.$store.dispatch("pbxMap", null);
     this.$store.dispatch("hostName", null);
     this.$store.dispatch("user", {});
     this.$store.dispatch("admin", false);
     this.$store.dispatch("token", "");
     this.$store.dispatch("adminToken", "");
     this.$store.dispatch("userAvatar", "");
     this.$store.dispatch("account", "");
     this.$store.dispatch("services", []);
     this.$store.dispatch("userPermissions", {});
     this.$store.dispatch("modules", {});
     this.$router.push("/login").then(() => location.reload());
    }
   }
  },
  initIcon(icon) {
   var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
   link.type = "image/x-icon";
   link.rel = "shortcut icon";
   link.href = icon;
   document.getElementsByTagName("head")[0].appendChild(link);
  },
  initTitle(title) {
   document.title = title;
  },
  async getCustomerCode() {
   try {
    const res = await axios.get(
     `${localStorage.getItem("apiUrl")}/api/v1.2/customer-from-origin/0000/${
      this.account
     }`,
     {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     }
    );
    this.$store.dispatch("pbx3cxCustomerCode", res.data?.code);
    await this.getPbx3cxConfiguration(res.data?.code);
    return res.data.code;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getPbx3cxConfiguration(code) {
   try {
    const res = await axios.get(
     `${localStorage.getItem(
      "apiUrl"
     )}/api/v1.2/pbx3cx-report-table-configurations/${code}
     `,
     {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     }
    );
    if (res.data) {
     if (res.data?.host_name) {
      this.$store.dispatch("hostName", res.data?.host_name);
      localStorage.setItem("hostName", res.data?.host_name);
     }
     this.$store.dispatch("pbx3cxTableConfig", res.data);
    } else {
     this.$store.dispatch("pbx3cxTableConfig", null);
    }
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getPbxHosts() {
   let expertStatsSubscription = false;
   if (this.userPermissions && this.userPermissions.permissions) {
    if (
     (this.userPermissions.permissions?.includes("pbx-hosts.*") ||
      this.userPermissions.permissions?.includes("pbx-hosts.view")) &&
     this.token &&
     this.account !== "ADMIN"
    ) {
     const code = await this.getCustomerCode();
     if (code) {
      try {
       const res = await axios.get(
        `${localStorage.getItem(
         "apiUrl"
        )}/api/v1.2/customer-pbx3cx-host?customer_code=${code}`,
        {
         headers: {
          Authorization: "Bearer " + localStorage.getItem("apiToken"),
         },
        }
       );

       if (res.data && res.data.length > 0) {
        console.log("res.data ==> ", res.data);
        this.$store.dispatch(
         "hostNames",
         res.data.map(res => res.host_name)
        );
        let dataHosts = res.data?.map(res => res.host_name);
        let dataHostName = dataHosts ? dataHosts[0] : null;
        if (!this.hostName || this.hostName === "null") {
         this.$store.dispatch("hostName", dataHostName);
         localStorage.setItem("hostName", dataHostName);
        }
        expertStatsSubscription = res?.data?.filter(
         host => host.host_name === this.hostName
        )[0].expert_statistics_active_subscription;
       }
       this.hostsNames = res.data.map(res => res.host_name);
      } catch (error) {
       this.errorHandling(error);
      } finally {
       this.getPbxMap();
      }
      localStorage.setItem(
       "active_expert_stats_subscription",
       expertStatsSubscription
      );

      this.$store.dispatch(
       "active_expert_stats_subscription",
       expertStatsSubscription
      );
     }
     //  try {
     //   const res = await axios.get(
     //    `${this.$cookie.getCookie("API")}/api/v1/pbxHosts`,
     //    {
     //     params: { customerAccount: this.account },
     //     headers: {
     //      Authorization: `Bearer ${this.token}`,
     //     },
     //    }
     //   );
     //   if (res.data && res.data.length > 0) {
     //    this.$store.dispatch("hostNames", res.data);
     //    if (
     //     !this.hostName ||
     //     this.hostName === "null" ||
     //     this.hostName !== res.data[0]
     //    ) {
     //     this.$store.dispatch("hostName", res.data[0]);
     //     localStorage.setItem("hostName", res.data[0]);
     //    }
     //   }
     //   this.hostsNames = res.data;
     //  } catch (error) {
     //   this.errorHandling(error);
     //  }
    }
   }
  },
  async getPbxMap() {
   if (this.hostName && this.hostName !== "null") {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/map`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     });
     this.$store.dispatch("pbxMap", res.data);
     localStorage.setItem("pbxMap", JSON.stringify(res.data));
     if (
      !res.data ||
      (res.data && Object.keys(res.data).length == 0) ||
      (res.data && !res.data.active)
     ) {
      this.$router.push("/page-unavailable");
     }

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  openSideNavHover(event) {
   this.sideHoverNav = event;
   if (
    this.sideHoverNav === "sidebar.invoices" ||
    this.sideHoverNav === "sidebar.tickets" ||
    this.sideHoverNav === "Documents"
   ) {
    this.sideNavHover = false;
    this.showSideNav = true;
   } else {
    this.sideNavHover = true;
   }
   this.hideNoNav = true;
  },
  async getApi() {
   try {
    /**
     * Local env development
     */
    if (process.env.VUE_APP_API) {
     localStorage.setItem("isLocal", true);
     localStorage.setItem("sandbox", true);
     localStorage.setItem("title", "BlueRockTEL Admin");
     localStorage.setItem("language", "fr");
     localStorage.setItem("API", process.env.VUE_APP_API);
     localStorage.setItem(
      "logo",
      "https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0503/logo-horizontal.jpg"
     );
     this.$store.dispatch(
      "logo",
      "https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0503/logo-horizontal.jpg"
     );

     if (
      !this.$cookie.getCookie("API") ||
      this.$cookie.getCookie("API") !== process.env.VUE_APP_API
     ) {
      localStorage.removeItem("token");
      this.$store.dispatch("token", null);
      if (this.$route.name !== "ResetPassword") {
       this.$router.push("/login");
      }
      this.$cookie.setCookie("API", process.env.VUE_APP_API, {
       expire: "21d",
      });
     }
    } else {
     /**
      * Get infos from AirControl API
      */
     axios
      .post("https://air-traffic.bluerocktel.net/api/flights")
      .then(res => {
       let apiData = {};
       console.log(res.data);
       if (Object.keys(res.data[0]).length > 1) {
        // apiData = res.data[0].encom_clients_sandbox;
        apiData = res.data[0].encom_clients_production;
        //apiData = res.data[0]["0535"];
        // apiData = res.data[0].cx_engine_clients;
        // apiData = res.data[0].cx_engine_clients_labs;

        // apiData = res.data[0].cx_engine_clients;
        localStorage.setItem("isLocal", true);
       } else {
        apiData = res.data[0].default;
       }
       if (apiData && apiData.active) {
        if (
         apiData.baseUrl.includes("https://telecom0999-clients.bluerock.tech")
        ) {
         this.setFavicon(apiData?.logo);
        }
        // if (
        //  apiData.baseUrl.includes("https://telecom0580-clients.bluerocktel.net")
        // ) {
        //  for (let index = 0; index < this.navigation.length; index++) {
        //   if (this.navigation[index].href.includes("/invoices"))
        //    this.navigation[index].show = false;
        //  }
        // }
        // this.initIcon(apiData.logo);
        this.initTitle(apiData.title);
        localStorage.setItem("sandbox", apiData.sandbox);
        localStorage.setItem("logo", apiData.logo);
        this.$store.dispatch("logo", apiData.logo);
        localStorage.setItem("title", apiData.title);
        localStorage.setItem("background", apiData.background);
        this.$store.dispatch("background", apiData.background);
        if (!localStorage.getItem("language")) {
         localStorage.setItem("language", apiData.lang);
        }
        if (apiData.demo) {
         this.isGuestUser = true;
         this.$store.dispatch("isGuest", true);
         localStorage.setItem("sandbox", true);
        }
        if (
         !this.$cookie.getCookie("API") ||
         this.$cookie.getCookie("API") !== apiData.baseUrl
        ) {
         localStorage.removeItem("token");
         localStorage.removeItem("adminToken");
         localStorage.removeItem("account");
         this.$store.dispatch("token", null);
         this.$store.dispatch("adminToken", null);
         if (this.$route.name !== "ResetPassword") {
          this.$router.push("/login");
         }
         this.$cookie.setCookie("API", apiData.baseUrl, {
          expire: "21d",
         });
        }
       }
      })
      .then(this.getPbxHosts())
      .catch(err => {
       if (err.response.status == 404) {
        axios
         .post("https://air-traffic.bluerocktel.net/api/flight-to")
         .then(res => {
          let apiData = {};
          apiData = res.data;
          if (apiData && apiData.active) {
           if (
            apiData.baseUrl.includes(
             "https://telecom0580-clients.bluerocktel.net"
            )
           ) {
            for (let index = 0; index < this.navigation.length; index++) {
             if (this.navigation[index].href.includes("/invoices"))
              this.navigation[index].show = false;
            }
           }

           this.initIcon(apiData.logo);
           this.initTitle(apiData.title);
           localStorage.setItem("sandbox", apiData.sandbox);
           localStorage.setItem("logo", apiData.logo);
           this.$store.dispatch("logo", apiData.logo);
           localStorage.setItem("title", apiData.title);
           localStorage.setItem("background", apiData.background);
           this.$store.dispatch("background", apiData.background);
           if (!localStorage.getItem("language")) {
            localStorage.setItem("language", apiData.lang);
           }
           if (apiData.demo) {
            this.isGuestUser = true;
            this.$store.dispatch("isGuest", true);
            localStorage.setItem("sandbox", true);
           }
           if (
            !this.$cookie.getCookie("API") ||
            this.$cookie.getCookie("API") !== apiData.baseUrl
           ) {
            localStorage.removeItem("token");
            localStorage.removeItem("adminToken");
            localStorage.removeItem("account");
            this.$store.dispatch("token", null);
            this.$store.dispatch("adminToken", null);
            if (this.$route.name !== "ResetPassword") {
             this.$router.push("/login");
            }
            this.$cookie.setCookie("API", apiData.baseUrl, {
             expire: "21d",
            });
           }
          }
         });
       }
      })
      .catch(err => {
       if (err.response.status == 404) {
        this.$router.push("/api-not-found");
       }
      });
    }
   } catch (error) {
    this.errorHandling(error);
   }
  },
  getIP() {
   //  axios.get("http://ip-api.com/json").then(x => console.log(x));
  },
  getTicketCount(...args) {
   let open = args[0];
   let closed = args[1];
   this.ticketNavigation[0].number = open;
   this.ticketNavigation[1].number = closed;
  },
  async getDocuments() {
   let documentsPermissions = ["customers.view", "customers.*"];
   let accounts = this.customerAccounts ? this.customerAccounts : [];
   let accountId =
    accounts.length > 0
     ? accounts.filter(acc => acc.customerAccount === this.account)[0]?.id
     : null;
   if (
    this.userPermissions &&
    this.userPermissions.permissions &&
    this.userPermissions.permissions.length > 0 &&
    this.userPermissions.permissions.some(
     permission => documentsPermissions.indexOf(permission) >= 0
    ) &&
    accountId
   ) {
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie(
       "API"
      )}/api/v1/documents?documentable_type=App\\Customer&documentable_id=${accountId}&customerAccount=${
       this.account
      }`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     if (res.data.length > 0) {
      this.navigationLong.push({
       name: "Documents",
       route: "Documents",
       show: true,
       href: "/documents",
       icon: "folder-open",
       current: false,
       hasSideNav: false,
       permissionName: "customers",
      });
     }
    } catch (error) {
     this.errorHandling(error);
     //  console.error(error);
    }
   }
  },
  async getInformationCollections() {
   let collectPermissions = [
    "information-collections.view",
    "information-collections.*",
   ];
   if (
    this.userPermissions &&
    this.userPermissions.permissions &&
    this.userPermissions.permissions.length > 0 &&
    this.userPermissions.permissions.some(
     permission => collectPermissions.indexOf(permission) >= 0
    )
   ) {
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie(
       "API"
      )}/api/v1/informationCollections?customerAccount=${this.account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     if (res.data.data.length > 0) {
      this.navigationLong.push({
       name: "sidebar.collects",
       route: "Folder",
       show: true,
       href: "/customer-files",
       icon: "file-signature",
       current: false,
       hasSideNav: false,
       permissionName: "information-collections",
      });
     }
    } catch (error) {
     console.error(error.response);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", error.response.data.message);
    }
   }
  },
  async getCertificates() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/tree-certificates?customer_account=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res.data && res.data.length > 0) {
     this.navigationLong.push({
      name: "Arbres plantés",
      show: true,
      icon: "certificate",
      current: false,
      children: [
       //  { name: "courses", href: "/training/courses" },
       //  { name: "myCourses", href: "/training/my-courses" },
       //  { name: "Quizzes", href: "/training/quizzes" },
       // { name: "Single Message", href: "/sms-single-message" },
      ],
      hasSideNav: true,
      permissionName: "invoicing",
     });

     for (let index = 0; index < res.data.length; index++) {
      const element = res.data[index];

      let name = "Certificat " + element.year;
      let number = element.number;
      let link = await this.getCertificateLink(element.year, element.key);

      let obj = {};

      obj["name"] = name;
      obj["number"] = number;
      obj["href"] = link;

      this.certificatesNavigation.push(obj);
     }
    }
   } catch (error) {
    console.error(error.response);
    this.$emit("setLoading", false);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getCertificateLink(year, key) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/tree-certificates/${year}/${key}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    );
    const file = new Blob([await res.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
   } catch (error) {
    console.error(error.response);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  setFavicon(url) {
   let link = document.querySelector("link[rel~='icon']");
   if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
   }
   link.href = url;
  },
 },
 watch: {
  isLoading: function (val) {
   if (val) {
    setInterval(() => {
     this.isLoading = false;
    }, 10000);
   }
  },
  // hostName: function (val) {
  //  this.getPbxHosts();
  // },
 },
 mounted() {
  // this.versionCheck();
  if (!localStorage.getItem("dashboard_tutorial")) {
   localStorage.setItem("dashboard_tutorial", 1);
  }
  if (
   localStorage.getItem("apiUrl") &&
   localStorage.getItem("apiUrl").includes("101")
  ) {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("customerAccounts");
   localStorage.removeItem("activeAccount");
   localStorage.removeItem("accountName");
   localStorage.removeItem("hostName");
   localStorage.removeItem("sandbox");
   localStorage.removeItem("hostTimeZone");
   localStorage.removeItem("hostContext");
   localStorage.removeItem("showBanner");
   localStorage.removeItem("queryElement");
   localStorage.removeItem("isLocal");
   localStorage.removeItem("user");
   localStorage.removeItem("pbxMap");
   localStorage.removeItem("apiUrl");
   localStorage.removeItem("apiToken");
   localStorage.removeItem("services");
   localStorage.removeItem("userPermissions");
   localStorage.removeItem("modules");
   this.$store.dispatch("pbxMap", null);
   this.$store.dispatch("hostName", null);
   this.$store.dispatch("user", {});
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$store.dispatch("services", []);
   this.$store.dispatch("modules", {});
   this.$router.push("/login").then(() => location.reload());
  }
 },
 created() {
  this.getApi();
  if (
   this.account &&
   this.account !== "ADMIN" &&
   this.user?.email !== "login@cx-engine.net"
  ) {
   this.getDocuments();
   this.getInformationCollections();
   this.getCertificates();
  }
 },
 computed: {
  ...mapGetters([
   "account",
   "hostName",
   "token",
   "api_v",
   "isLoading",
   "modules",
   "user",
   "customerAccounts",
  ]),
  ...mapState({
   userPermissions: state => state.userPermissions,
   services: state => state.services,
  }),
  navigationLong() {
   return this.navigationLong.map(item => {
    if (
     this.userPermissions &&
     this.userPermissions.permissions &&
     this.userPermissions.permissions.length &&
     item.permissions &&
     item.permissions.length
    ) {
     if (
      this.userPermissions.permissions.some(
       permission => item.permissions.indexOf(permission) >= 0
      )
     ) {
      item.show = true;
     }
    }
    // if (
    //  item.name === "overview" &&
    //  this.user &&
    //  this.user.email &&
    //  this.adminUsers.indexOf(this.user.email) >= 0
    // ) {
    //  item.show = true;
    // }
    return item;
   });
  },
  updatedSpecialNavigation() {
   return this.specialNavigation.map(item => {
    if (
     this.userPermissions &&
     this.userPermissions.permissions &&
     this.userPermissions.permissions.length &&
     item.permissions &&
     item.permissions.length
    ) {
     if (
      this.userPermissions.permissions.some(
       permission => item.permissions.indexOf(permission) >= 0
      )
     ) {
      item.show = true;
     }
    }
    return item;
    // const objService = this.services.find((service) => service.key === item.serviceName);

    // if (objService) {
    //  const { enabled, can_be_enabled } = objService;
    //  item.show = enabled || (!enabled && can_be_enabled);
    // }

    // return item;
   });
  },
 },
};
</script>

<style>
.side_nav_width {
 min-width: 15rem;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 6rem;
 z-index: 10;
}
.side_nav_off_width {
 width: 50px;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 6rem;
 z-index: 10;
}
.main__sidenav--on {
 margin-left: 20rem;
 margin-top: 3rem;
}
.main__sidenav--off {
 margin-left: 8rem;
 margin-top: 3rem;
}
.main__no_sidenav {
 margin-left: 5rem;
 margin-top: 3rem;
}
@media (max-width: 480px) {
 .main__sidenav--on {
  margin-left: 0rem;
  margin-top: 3rem;
 }
 .main__sidenav--off {
  margin-left: 0rem;
  margin-top: 3rem;
 }
 .main__no_sidenav {
  margin-left: 0rem;
  margin-top: 3rem;
 }
}

html {
 scroll-behavior: smooth;
}
input[type="range"] {
 accent-color: #1a6c68;
}
input[type="checkbox"] {
 color: #1a6c68 !important;
}
</style>
