<template>
 <div
  class="fixed bg-gray-100 impersonate_z_index inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div
   class="flex items-center justify-center impersonate_card_height_position pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"></div>

   <!-- This element is to trick the browser into centering the modal contents. -->
   <span
    class="hidden sm:inline-block sm:align-middle sm:h-screen"
    aria-hidden="true"
    >&#8203;</span
   >
   <div
    class="rounded-lg inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
    <div>
     <div class="mt-3 text-center sm:mt-5">
      <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
       {{ $t("impersonate.popupTitle") }}
      </h3>
     </div>
     <form
      class="mt-8 space-y-6"
      @submit.prevent="impersonateUser(email)"
      method="POST">
      <input type="hidden" name="remember" value="true" />
      <div class="-space-y-px">
       <div>
        <label for="email-address" class="sr-only email_label"
         >Email address</label
        >
        <div class="mt-1 relative rounded-md">
         <input
          id="email-address"
          v-model="email"
          name="email"
          type="email"
          autocomplete="email"
          required=""
          :class="[
           this.error
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded block w-full px-3 py-2 focus:z-10 sm:text-sm'
            : 'appearance-none rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm',
          ]"
          placeholder="Email address" />
         <div
          v-if="this.error"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
           class="h-5 w-5 text-red-500"
           aria-hidden="true" />
         </div>
        </div>

        <p
         v-if="this.error"
         class="mt-1 ml-1 text-sm text-red-600"
         id="email-error">
         {{
          $tc("entityIncorrectorNotFound", 2, {
           entity: this.$t("emailAddress"),
          })
         }}
        </p>
       </div>
      </div>

      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
       <button
        type="submit"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 body__header--button font-medium text-white focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">
        {{ $t("dialog.confirm") }}
       </button>
       <a
        @click="signOut()"
        href="#"
        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mr-3 sm:w-auto sm:text-sm sm:mt-0 mt-1">
        {{ $t("header.logout") }}
       </a>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";

import { ExclamationCircleIcon } from "@heroicons/vue/solid";
export default {
 name: "impersonate",
 props: [],
 components: { ExclamationCircleIcon },
 data() {
  return {
   email: "",
   error: false,
   account: null,
  };
 },
 methods: {
  reloadPage() {
   location.reload();
  },
  async impersonateUser(email) {
   const userPermissions = { permissions: [] };
   this.error = false;
   try {
    await axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/impersonate?email=${email}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
       },
      }
     )
     .then(res => {
      localStorage.removeItem("first_login");
      localStorage.setItem("token", res.data.token);
      this.$store.dispatch("token", res.data.token);
      this.$store.commit(
       "setUserPermissions",
       userPermissions ? userPermissions : null
      );
      localStorage.setItem("userPermissions", JSON.stringify(userPermissions));
      if (
       res.data.user.accounts.includes("ADMIN") ||
       res.data.user.activeAccount.includes("ADMIN")
      ) {
       localStorage.setItem("adminToken", res.data.token);
       this.$store.dispatch("adminToken", res.data.token);
      }
      localStorage.setItem("avatar", res.data.user.avatar);
      if (!res.data.user.activeAccount) {
       localStorage.setItem("account", res.data.user.accounts[0]);
       this.$store.dispatch("account", res.data.user.accounts[0]);
       this.account = res.data.user.accounts[0];
      } else {
       localStorage.setItem("account", res.data.user.activeAccount);
       this.$store.dispatch("account", res.data.user.activeAccount);
       this.account = res.data.user.activeAccount;

       localStorage.setItem(
        "customerAccounts",
        JSON.stringify(res.data.customerAccounts)
       );

       const activeAccount = res.data.customerAccounts.filter(
        account => account.customerAccount === res.data.user.activeAccount
       );
       if (activeAccount.length > 0) {
        const storeActiveAccount =
         activeAccount[0].name + " - " + activeAccount[0].customerAccount;
        localStorage.setItem("activeAccount", storeActiveAccount);
        this.$store.dispatch("activeAccount", storeActiveAccount);
       }
      }

      localStorage.setItem("showBanner", true);
      this.$store.dispatch("user", res.data.user);
      localStorage.setItem("user", JSON.stringify(res.data.user));

      const initials = res.data.user.name
       .split(" ")
       .map(name => name.charAt(0))
       .join("");
      localStorage.setItem("initials", initials);
      this.$store.dispatch("userAvatar", res.data.user.avatar);
     })
     .finally(() => {
      if (this.account !== "ADMIN") {
       this.getPbxHosts(localStorage.getItem("token"), this.account).then(
        () => {
         this.getServices(this.account).then(() => {
          this.getModules().then(() => {
           this.getMyPermissions(this.account).then(res => {
            this.$nextTick(() => {
             this.$router.push("/").then(() => location.reload());
            });
           });
          });
         });
        }
       );
      } else {
       this.$router.push("/impersonate").then(() => {
        this.$emit("setLoading", false);
       });
      }
     });
   } catch (error) {
    this.errorHandling(error);
    this.error = true;
    this.impersonatePopup = false;
   }
  },
  async getCustomerCode(account) {
   try {
    const res = await axios.get(
     `${localStorage.getItem(
      "apiUrl"
     )}/api/v1.2/customer-from-origin/0000/${account}`,
     {
      headers: {
       Authorization: "Bearer " + token,
      },
     }
    );
    return res.data.code;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getPbxHosts(token, account) {
   if (this.userPermissions && this.userPermissions.permissions) {
    if (
     (this.userPermissions.permissions?.includes("pbx-hosts.*") ||
      this.userPermissions.permissions?.includes("pbx-hosts.view")) &&
     this.token &&
     this.account !== "ADMIN"
    ) {
     const code = await this.getCustomerCode(token, account);
     try {
      const res = await axios.get(
       `${localStorage.getItem(
        "apiUrl"
       )}/api/v1.2/customer-pbx3cx-host?customer_code=${code}`,
       {
        headers: {
         Authorization: "Bearer " + token,
        },
       }
      );

      if (res.data && res.data.length > 0) {
       this.$store.dispatch(
        "hostNames",
        res.data.map(res => res.host_name)
       );
       let dataHostName = res.data.map(res => res.host_name)
        ? res.data.map(res => res.host_name)[0]
        : null;
       if (
        !this.hostName ||
        this.hostName === "null" ||
        this.hostName !== dataHostName
       ) {
        this.$store.dispatch("hostName", dataHostName);
        localStorage.setItem("hostName", dataHostName);
       }
      }
     } catch (error) {
      this.errorHandling(error);
     }
     //  try {
     //   const res = await axios.get(
     //    `${this.$cookie.getCookie("API")}/api/v1/pbxHosts`,
     //    {
     //     params: { customerAccount: this.account },
     //     headers: {
     //      Authorization: `Bearer ${this.token}`,
     //     },
     //    }
     //   );
     //   if (res.data && res.data.length > 0) {
     //    this.$store.dispatch("hostNames", res.data);
     //    if (
     //     !this.hostName ||
     //     this.hostName === "null" ||
     //     this.hostName !== res.data[0]
     //    ) {
     //     this.$store.dispatch("hostName", res.data[0]);
     //     localStorage.setItem("hostName", res.data[0]);
     //    }
     //   }
     //   this.hostsNames = res.data;
     //  } catch (error) {
     //   this.errorHandling(error);
     //  }
    }
   }
  },
  async getModules() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/modules`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    const data = JSON.stringify(res.data);
    localStorage.setItem("modules", data);
    this.$store.commit("setModules", res.data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getServices(account) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/customer-services?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    const data = JSON.stringify(res.data);
    localStorage.setItem("services", data);
    this.$store.commit("setServices", res.data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getMyPermissions(account) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.$store.commit("setUserPermissions", res.data ? res.data : null);
    // const data = JSON.stringify(res.data);
    localStorage.setItem("userPermissions", JSON.stringify(res.data));
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("customerAccounts");
   localStorage.removeItem("activeAccount");
   localStorage.removeItem("accountName");
   localStorage.removeItem("hostName");
   localStorage.removeItem("sandbox");
   localStorage.removeItem("hostTimeZone");
   localStorage.removeItem("hostContext");
   localStorage.removeItem("showBanner");
   localStorage.removeItem("queryElement");
   localStorage.removeItem("isLocal");
   localStorage.removeItem("user");
   localStorage.removeItem("pbxMap");
   localStorage.removeItem("hostName");
   localStorage.removeItem("apiUrl");
   localStorage.removeItem("apiToken");
   localStorage.removeItem("services");
   localStorage.removeItem("userPermissions");
   localStorage.removeItem("modules");
   this.$store.dispatch("pbxMap", null);
   this.$store.dispatch("hostName", null);
   this.$store.dispatch("user", {});
   this.$store.dispatch("admin", false);
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$store.dispatch("services", []);
   this.$store.dispatch("userPermissions", {});
   this.$store.dispatch("modules", {});
   this.$router.push("/login");
  },
 },
 mounted() {},
};
</script>

<style>
.impersonate_z_index {
 z-index: 9999;
}
.impersonate_card_height_position {
 min-height: 100vh;
}
</style>
