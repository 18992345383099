import { createStore } from "vuex";
import axios from "axios";

export default createStore({
 state: {
  showBanner: false,
  search: false,
  pbx3cxCustomerCode: null,
  pbx3cxTableConfig: {
   initial_interval: 20,
   count_interval: 2,
   following_interval: 10,
  },
  userPermissions: JSON.parse(localStorage.getItem("userPermissions")) || {},
  user: JSON.parse(localStorage.getItem("user")) || {},
  avatar: localStorage.getItem("avatar") || "",
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  adminToken: localStorage.getItem("adminToken") || null,
  sidebar: false,
  activeAccount: localStorage.getItem("activeAccount") || null,
  active_expert_stats_subscription:
   JSON.parse(localStorage.getItem("active_expert_stats_subscription")) ||
   false,
  customerAccounts: JSON.parse(localStorage.getItem("customerAccounts")) || [],
  account: localStorage.getItem("account") || null,
  language: localStorage.getItem("language") || "fr",
  logo: localStorage.getItem("logo") || null,
  background: localStorage.getItem("background") || null,
  pbxMap: localStorage.getItem("pbxMap")
   ? JSON.parse(localStorage.getItem("pbxMap"))
   : null,
  pbxElements: undefined,
  pbxElementType: undefined,
  pbxPeriodSelected: null,
  pbxTimeSelected: ["00:00", "23:59"],
  pbxTimeSelectedEnd: null,
  buttonSelector: null,
  hostName: localStorage.getItem("hostName") || null,
  hostNames: null,
  hostContext: localStorage.getItem("hostContext") || 0,
  hostTimeZone: 0,
  isGuest: false,
  groupSelected: false,
  groupSelectedName: [],
  pagination: localStorage.getItem("pagination") || 15,
  modules: JSON.parse(localStorage.getItem("modules")) || {},
  services: JSON.parse(localStorage.getItem("services")) || [],
  baseUrl: "",
  isLoading: false,
  api_v: localStorage.getItem("api_v") || null,
  pbxCustomDate: null,
 },
 mutations: {
  pbxCustomDate(state, pbxCustomDate) {
   state.pbxCustomDate = pbxCustomDate;
  },
  active_expert_stats_subscription(state, active_expert_stats_subscription) {
   state.active_expert_stats_subscription = active_expert_stats_subscription;
  },
  setPbx3cxCustomerCode(state, pbx3cxCustomerCode) {
   state.pbx3cxCustomerCode = pbx3cxCustomerCode;
   // localStorage.setItem("pbx3cxCustomerCode", pbx3cxCustomerCode);
  },
  setPbx3cxTableConfiguration(state, pbx3cxTableConfig) {
   state.pbx3cxTableConfig = pbx3cxTableConfig;
   //    localStorage.setItem("pbx3cxTableConfig", pbx3cxTableConfig);
  },
  setActiveAccount(state, activeAccount) {
   state.activeAccount = activeAccount;
   localStorage.setItem("activeAccount", activeAccount);
  },
  setCustomerAccounts(state, customerAccounts) {
   state.customerAccounts = customerAccounts;
   localStorage.setItem("customerAccounts", customerAccounts);
  },
  baseUrl(state, baseUrl) {
   state.baseUrl = baseUrl;
  },
  setLoading(state, isLoading) {
   state.isLoading = isLoading;
  },
  setModules(state, modules) {
   state.modules = modules;
   localStorage.setItem("modules", JSON.stringify(modules));
  },
  setServices(state, services) {
   state.services = services;
   localStorage.setItem("services", JSON.stringify(services));
  },
  setUserPermissions(state, permissions) {
   if (permissions) {
    state.userPermissions = permissions;
    localStorage.setItem("userPermissions", JSON.stringify(permissions));
   }
  },
  modules(state, modules) {
   state.modules = modules;
  },
  isGuest(state, isGuest) {
   state.isGuest = isGuest;
  },
  showBanner(state, showBanner) {
   state.showBanner = showBanner;
  },
  user(state, user) {
   state.user = user;
  },
  token(state, token) {
   state.token = token;
  },
  adminToken(state, adminToken) {
   state.adminToken = adminToken;
  },
  avatar(state, avatar) {
   state.avatar = avatar;
  },
  account(state, account) {
   state.account = account;
  },
  language(state, language) {
   state.language = language;
  },
  search(state, search) {
   state.search = search;
  },
  logo(state, logo) {
   state.logo = logo;
  },
  background(state, background) {
   state.background = background;
  },
  pbxElements(state, pbxElements) {
   state.pbxElements = pbxElements;
  },
  pbxElementType(state, pbxElementType) {
   state.pbxElementType = pbxElementType;
  },
  pbxPeriodSelected(state, pbxPeriodSelected) {
   state.pbxPeriodSelected = pbxPeriodSelected;
  },
  pbxTimeSelected(state, pbxTimeSelected) {
   state.pbxTimeSelected = pbxTimeSelected;
  },
  pbxTimeSelectedEnd(state, pbxTimeSelectedEnd) {
   state.pbxTimeSelectedEnd = pbxTimeSelectedEnd;
  },
  buttonSelector(state, buttonSelector) {
   state.buttonSelector = buttonSelector;
  },
  hostName(state, hostName) {
   state.hostName = hostName;
  },
  api_v(state, api_v) {
   state.api_v = api_v;
  },
  hostNames(state, hostNames) {
   state.hostNames = hostNames;
  },
  hostContext(state, hostContext) {
   state.hostContext = hostContext;
  },
  hostTimeZone(state, hostTimeZone) {
   state.hostTimeZone = hostTimeZone;
  },
  groupSelected(state, groupSelected) {
   state.groupSelected = groupSelected;
  },
  groupSelectedName(state, groupSelectedName) {
   state.groupSelectedName = groupSelectedName;
  },
  pagination(state, pagination) {
   state.pagination = pagination;
  },
  pbxMap(state, pbxMap) {
   state.pbxMap = pbxMap;
  },
  services(state, services) {
   state.services = services;
  },
 },
 actions: {
  active_expert_stats_subscription(context, active_expert_stats_subscription) {
   context.commit(
    "active_expert_stats_subscription",
    active_expert_stats_subscription
   );
  },
  pbxCustomDate(context, pbxCustomDate) {
   context.commit("pbxCustomDate", pbxCustomDate);
  },
  pbx3cxCustomerCode(context, pbx3cxCustomerCode) {
   context.commit("setPbx3cxCustomerCode", pbx3cxCustomerCode);
  },
  pbx3cxTableConfig(context, pbx3cxTableConfig) {
   context.commit("setPbx3cxTableConfiguration", pbx3cxTableConfig);
  },
  activeAccount(context, activeAccount) {
   context.commit("setActiveAccount", activeAccount);
  },
  customerAccounts(context, customerAccounts) {
   context.commit("setCustomerAccounts", customerAccounts);
  },
  baseUrl(context, baseUrl) {
   context.commit("baseUrl", baseUrl);
  },
  setLoading(context, isLoading) {
   context.commit("setLoading", isLoading);
  },
  modules(context, modules) {
   context.commit("modules", modules);
  },
  isGuest(context, isGuest) {
   context.commit("isGuest", isGuest);
  },
  showBanner(context, showBanner) {
   context.commit("showBanner", showBanner);
  },
  async getMyPermissions(context, { account, baseUrl }) {
   try {
    context.commit("setLoading", true);
    const res = await axios.get(
     `${baseUrl}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    context.commit("setUserPermissions", res.data);
    return res.data;
   } catch (error) {
    console.error(error);
    throw error;
   }
  },
  async getServices(context, { account, baseUrl }) {
   try {
    context.commit("setLoading", true);
    const res = await axios.get(
     `${baseUrl}/api/v1/customer-services?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    context.commit("setServices", res.data);
    return res.data;
   } catch (error) {
    console.error(error);
    throw error;
   }
  },
  async getModules(context, { baseUrl }) {
   try {
    context.commit("setLoading", true);
    const res = await axios.get(`${baseUrl}/api/v1/modules`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    context.commit("setModules", res.data);
    return res.data;
   } catch (error) {
    console.error(error);
    throw error;
   }
  },
  user(context, user) {
   context.commit("user", user);
  },
  token(context, token) {
   context.commit("token", token);
  },
  adminToken(context, adminToken) {
   context.commit("adminToken", adminToken);
  },
  userAvatar(context, avatar) {
   context.commit("avatar", avatar);
  },
  account(context, account) {
   context.commit("account", account);
  },
  language(context, language) {
   context.commit("language", language);
  },
  search(context, search) {
   context.commit("search", search);
  },
  logo(context, logo) {
   context.commit("logo", logo);
  },
  background(context, background) {
   context.commit("background", background);
  },
  pbxElements(context, pbxElements) {
   context.commit("pbxElements", pbxElements);
  },
  pbxElementType(context, pbxElementType) {
   context.commit("pbxElementType", pbxElementType);
  },
  pbxPeriodSelected(context, pbxPeriodSelected) {
   context.commit("pbxPeriodSelected", pbxPeriodSelected);
  },
  pbxTimeSelected(context, pbxTimeSelected) {
   context.commit("pbxTimeSelected", pbxTimeSelected);
  },
  pbxTimeSelectedEnd(context, pbxTimeSelectedEnd) {
   context.commit("pbxTimeSelectedEnd", pbxTimeSelectedEnd);
  },
  buttonSelector(context, buttonSelector) {
   context.commit("buttonSelector", buttonSelector);
  },
  hostName(context, hostName) {
   context.commit("hostName", hostName);
  },
  api_v(context, api_v) {
   context.commit("api_v", api_v);
  },
  hostNames(context, hostNames) {
   context.commit("hostNames", hostNames);
  },
  hostContext(context, hostContext) {
   context.commit("hostContext", hostContext);
  },
  hostTimeZone(context, hostTimeZone) {
   context.commit("hostTimeZone", hostTimeZone);
  },
  groupSelected(context, groupSelected) {
   context.commit("groupSelected", groupSelected);
  },
  groupSelectedName(context, groupSelectedName) {
   context.commit("groupSelectedName", groupSelectedName);
  },
  pagination(context, pagination) {
   context.commit("pagination", pagination);
  },
  pbxMap(context, pbxMap) {
   context.commit("pbxMap", pbxMap);
  },
  services(context, services) {
   context.commit("services", services);
  },
 },
 getters: {
  active_expert_stats_subscription: state => {
   return state.active_expert_stats_subscription;
  },
  pbxCustomDate: state => {
   return state.pbxCustomDate;
  },
  pbx3cxCustomerCode: state => {
   return state.pbx3cxCustomerCode;
  },
  pbx3cxTableConfig: state => {
   return state.pbx3cxTableConfig;
  },
  activeAccount: state => {
   return state.activeAccount;
  },
  customerAccounts: state => {
   return state.customerAccounts;
  },
  baseUrl: state => {
   return state.baseUrl;
  },
  isLoading: state => {
   return state.isLoading;
  },
  modules: state => {
   return state.modules;
  },
  isGuest: state => {
   return state.isGuest;
  },
  showBanner: state => {
   return state.showBanner;
  },
  userPermissions: state => {
   return state.userPermissions;
  },
  user: state => {
   return state.user;
  },
  token: state => {
   return state.token;
  },
  adminToken: state => {
   return state.adminToken;
  },
  avatar: state => {
   return state.user;
  },
  account: state => {
   return state.account;
  },
  language: state => {
   return state.language;
  },
  search: state => {
   return state.search;
  },
  logo: state => {
   return state.logo;
  },
  background: state => {
   return state.background;
  },
  pbxElements: state => {
   return state.pbxElements;
  },
  pbxElementType: state => {
   return state.pbxElementType;
  },
  pbxTimeSelected: state => {
   return state.pbxTimeSelected;
  },
  pbxPeriodSelected: state => {
   return state.pbxPeriodSelected;
  },
  pbxTimeSelectedEnd: state => {
   return state.pbxTimeSelectedEnd;
  },
  buttonSelector: state => {
   return state.buttonSelector;
  },
  hostName: state => {
   return state.hostName;
  },
  api_v: state => {
   return state.api_v;
  },
  hostNames: state => {
   return state.hostNames;
  },
  hostContext: state => {
   return state.hostContext;
  },
  hostTimeZone: state => {
   return state.hostTimeZone;
  },
  groupSelected: state => {
   return state.groupSelected;
  },
  groupSelectedName: state => {
   return state.groupSelectedName;
  },
  pagination: state => {
   return state.pagination;
  },
  pbxMap: state => {
   return state.pbxMap;
  },
  services: state => {
   return state.services;
  },
 },
});
